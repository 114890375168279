<template>
<v-container fluid>
  <v-row>
    <v-col cols="9">
      <v-container fluid>
        <div v-if="pickedWeek" class="text-h6">下属周报 {{ pickedWeek.year }}年{{ pickedWeek.month }}月第{{ pickedWeek.week }}周</div>
        <div class="my-2">
          <v-btn @click="openNotificationDlg" color="primary" small>提醒大家写周报</v-btn>
        </div>
        <v-divider class="mt-3"></v-divider>
        <v-row style="height:300px;overflow:auto;">
          <v-container fluid>
            <!-- missed sub users -->
            <v-row v-if="missedSubUsers.length > 0">
              <v-col class="justify-center align-center d-flex" cols="2">
                <div>
                  <div class="red--text pl-4">{{ missedSubUsers.length }}</div>
                  <div class="red--text">未提交</div>
                </div>
              </v-col>
              <v-col>
                <div v-for="(user, i) in missedSubUsers" :key="i">
                  <v-divider v-if="i!==0"></v-divider>
                  <div class="d-flex justify-start align-center ma-2">
                    <UserAvatar class="ma-1" :name="user.employeeName.charAt(0)" width='30px' height='30px' :userId="user.id" />
                    <div style="width:100px;">{{ user.employeeName }}</div>
                    <v-spacer></v-spacer>
                    <div style="cursor:pointer;" @click="openNotificationDlg" class="blue--text">提醒ta写周报</div>
                    <v-spacer></v-spacer>
                  </div>
                  <v-divider v-if="i!==missedSubUsers.length-1"></v-divider>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- checked sub users -->
            <v-row v-if="checkedSubUsers.length > 0">
              <v-col class="justify-center align-center d-flex" cols="2">
                <div>
                  <div class="green--text pl-4">{{ checkedSubUsers.length }}</div>
                  <div class="green--text">按时提交</div>
                </div>
              </v-col>
              <v-col>
                <div v-for="(user, i) in checkedSubUsers" :key="i">
                  <v-divider v-if="i!==0"></v-divider>
                  <div class="d-flex justify-start align-center ma-2">
                    <UserAvatar class="ma-1" :name="user.employeeName.charAt(0)" width='30px' height='30px' :userId="user.id" />
                    <div style="width:100px;">{{ user.employeeName }}</div>
                    <v-spacer></v-spacer>
                    <!-- <div class="blue--text">提醒ta写周报</div> -->
                    <!-- <v-spacer></v-spacer> -->
                  </div>
                  <v-divider v-if="i!==checkedSubUsers.length-1"></v-divider>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
      </v-container>
    </v-col>
    <v-col>
      <week-calendar :expireds="expireds" :actives="actives" @range="setRange" @pick="pickWeek" :current="current"></week-calendar>
    </v-col>
  </v-row>
  <!-- reports -->
  <v-row>
    <TabFirstItem :active="actives" v-for="(rp, index) in thisWeekReports" :key="index" :report="rp" type="weekly" @displayRightSummaryEdit="openRightSummaryEditor" />
  </v-row>
  <sub-notification-dlg @agree="sendNotification" ref="subNotificationDlg"></sub-notification-dlg>
</v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'SubWeeklyReports',
  components: {
    WeekCalendar: () => import ('@/components/summary/WeekCalendar.vue'),
    UserAvatar: () => import('@/components/common/UserAvatar.vue'),
    TabFirstItem: () => import('@/components/summary/TabFirstItem.vue'),
    SubNotificationDlg: () => import('@/components/summary/SubNotificationDlg.vue')
  },
  data: () => ({
    expireds: [],
    current: null,
    pickedWeek: null,
    week: null,
    checkedSubUsers: []
  }),
  computed: {
    ...mapGetters('report', ['allWeeklyReport']),
    ...mapGetters('auth', ['authUser']),
    ...mapGetters('user', ['subUsers']),
    thisWeekReports: function () {
      this.checkedSubUsers = [];
      if(!this.pickedWeek) return ;
      const weekString = `${this.pickedWeek.year}-${this.pickedWeek.month}/${this.pickedWeek.week}`;
      return this.allWeeklyReport.filter(report => {
        if(report.rp_date == weekString) {
          const index = this.subUsers.findIndex(user => user.id == report.rp_creator);
          if(index !== -1) {
            this.checkedSubUsers.push(this.subUsers[index]);
            return report;
          }
        }
      })
    },
    missedSubUsers: function () {
      return this.subUsers.filter(user => {
        const index = this.checkedSubUsers.findIndex(cu => cu.id == user.id);
        if(index == -1) {
          return user;
        }
      })
    },
    actives: function () {
      let temp = [];
      if(!this.pickedWeek) return;
      this.allWeeklyReport.map(report => {
        const index = this.subUsers.findIndex(user => user.id == report.rp_creator);
        if(index !== -1) {
          temp.push(report.rp_date);
        }
      });
      return temp;
    }
  },
  methods: {
    ...mapActions('report', [ 'getAllWeeklyReportWithCreator']),
    ...mapActions('user', ['getSubUsers']),
    pickWeek(e) {
      this.pickedWeek = {
        year: e.split('-')[0],
        month: e.split('-')[1].split('/')[0],
        week: e.split('-')[1].split('/')[1]
      }
    },
    setRange(e) {
      this.week = e;
    },
    openRightSummaryEditor(prop) {
      let param = {
        value: prop,
        type: "daily",
      }
      this.$emit("displayRightSummaryEdit", param);
    },
    openNotificationDlg() {
    this.$refs.subNotificationDlg.open({users: this.missedSubUsers, type: 'weekly'});
    },
    sendNotification(e) {

    }
  },
  mounted() {
    const today = new Date();
    this.current = {
      year: today.getFullYear(),
      month: today.getMonth()
    };
    this.getAllWeeklyReportWithCreator({
      rp_type: 1
    });
  }
}
</script>